.bg-primary-gradient {
    background-image: linear-gradient(to left, #0db2de 0%, #005bea 100%) !important;
}

.bg-danger-gradient {
    background-image: linear-gradient(
45deg, #f93a5a, #f7778c) !important;
}

.bg-success-gradient {
    background-image: linear-gradient(to left, #48d6a8 0%, #029666 100%) !important;
}

.bg-warning-gradient {
    background-image: linear-gradient(to left, #efa65f, #f76a2d) !important;
}

.btn-theme-primary {
    background: radial-gradient(circle, #f36227 0%, #fd841d 50%, #fccd45 100%);
    color: #fff;
}

.round-box-1 {
    border-color:  #fca100!important;
    color: #fca100;
}
.round-box-2 {
    border-color:  #005bea!important;
    color: #005bea;
}
.round-box-3 {
    border-color:  #029666!important;
    color: #029666;
}
.round-box-4 {
    border-color:  #f7778c!important;
    color: #f7778c;
}
.round-box-5 {
    border-color:  #be09cb!important;
}

.bg-box-one{
    background-color: #ffff;
}