


$themeColor:#9F2241;
$themeColorOpacity:#9F22418c;

.form-group{
    margin-bottom: 25px;
}
.form-field {
    margin-top: 0px;
    .form-group {
        .form-control {
            @include input-theme;
            &::placeholder {
                @include placeholder;
            }
        }
        textarea {
            min-height: 80px;
        }
        &.caret {
            position: relative;
            .fa-caret-down {
                font-size: 20px;
                color: #8d6719;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
            }
        }
    }
    .form-check {
        margin-bottom: 30px;
        .form-check-label {
            font-size: 12px;
            color: #797979;
            font-weight: 400;
            word-break: keep-all;
        }
    }
    .custom-checkbox .form-check-input:checked ~ .custom-control-label::before {
        width: 20px;
        height: 20px;
        background-color: #d2b271;
    }
    .custom-checkbox .form-check-input:checked ~ .custom-control-label::after {
        width: 20px;
        height: 20px;
    }
    .theme-btn {
        padding: 0 20px;
        .btn {
            @include theme-btn;
            border-radius: 10px;
            background-color: #8d6719;
            font-size: 17px;
            color: #ffffff;
            font-weight: 500;
            width: 100%;
        }
        @include mobilePotrait {
            padding: 0 0;
        }
    }
}

.form-group{
	.react-datepicker-wrapper, .react-datepicker__input-container{
		width: 100%;
	}
	input{
		width: 100%;
		font-size: 14px !important;
		min-height: 40px !important;
		padding: .375rem .75rem;
		font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
		&::placeholder{
			font-size: 14px !important;
		}
	}
}



.table-order{
	&::-webkit-scrollbar {
		width: 20px;
		height: 12px;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px grey;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background: $themeColorOpacity;
		border-radius: 10px;
		&:hover {
			background: $themeColor;
		}
	}
	background-color: #fff;
	.no-data {
		position: absolute;
		top: 126px;
		left: 50%;
		transform: translateX(-50%);
		font-weight: 700;
		padding: 0!important;
		
			font-size: 34px;
		color: #21252940;
		text-align: center;

		@include mobileLandscape{
			font-size: 14px;
		}
		
	}
}


.table-order {
	overflow-x: overlay;
	width: 100%;
	border: 1px solid #d8d8d8;
	border-radius: 6px;
	position: relative;
	min-height: 250px;
	.loader-wrapper{
		position: absolute;
		
	}
	// .action-top{
	// 	display: flex;
	// 	align-items: center;
	// 	white-space: nowrap;
	// }
	
	table {
		width: 100%;
		border-style: hidden;
		box-shadow: 0 0 0 1px #f1f1f1;
		border-radius: 6px;
		overflow: hidden;
	
	}
	tr {
		border-bottom: 1px solid #19198e4a;
		background-color: #fff;
		td {
			padding: 20px 15px;
			color: #080847;
			font-size: 14px;
			background-color: #fff;
			text-transform: capitalize;
		}
		th {
			&.s-no{
				background-color: transparent;
				padding-left: 25px;
			}
			padding: 12px 15px;
			padding: 18px 15px;
			background-color: #efefff;
			font-size: 12px;
			font-weight: normal;
			color: $themeColor;
			text-transform: uppercase;
			.custom-control-label {
				color: #080847;
				font-size: 12px;
			}
		}
		&:nth-last-child(2){
			td{
				.dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top]{
					transform: translate(-144px, -24px)!important;

				}
			}
		}
	}
}

.project-table {
	margin-bottom: 30px;
	.top-search{
		max-width: 414px;
		margin: 0 0 25px auto;
		&.mb-0{
			
			margin-bottom: 15px!important;
		
		
	}
		input{
			background-color: #efefff;
		}
	}
	.table-order-box{
		border: 1px solid #d8d8d8;
    border-radius: 6px;
	.tab-head{
		border-radius: 6px;
		.icon-position{
			.icon{
				top: 7px;
			}
		} 
	}
	}
}

.icons-action{
    svg{
        font-size: 18px;
        color: #fc821d;
    }
}

.heading-top{
    margin-bottom: 20px;
}

select:required:invalid {
	color: gray;
  }
  option[value=""][disabled] {
	display: none;
  }
  option {
	color: black;
  }

  .form-select{
      font-size: 14px;
  }