img{max-width: 100%;}

.logo-name{
    h1{
        padding: 10px;
        color: #fff;
        font-size: 34px;
    }
}
.light-theme {
	.app-sidebar {
		background: linear-gradient(#ffab7cf2 40%, #fccd45 70%) !important;
		background: radial-gradient(circle, #f36227 0%, #fd841d 50%, #fccd45 100%);
	}
}

.body-margin .main{
    margin-left: 229px;
    padding: 96px 30px 30px 30px;
}
.app-sidebar {
    position: fixed;
	width: 229px;
    left: 0;
    top: 0;
	overflow: auto;
	z-index: 10;
	transition: left 0.3s ease, width 0.3s ease;
	transition: left 0.5s ease, width 0.5s ease;
	height: 100vh;
	overflow: hidden;
}
.app-menu {
    padding-left: 0;
    list-style: none;
    margin-bottom: 0;
    margin-top: 25px;
    height: 85%;
    overflow: auto;
	overflow-X: hidden;
}
.light-theme {
	.app-menu__item.active {
		background: radial-gradient(circle, #f36227 0%, #fd841d 50%, #fccd45 100%) !important;
		color: #fff !important;
	}
	.app-menu__item {
		&:hover {
			background: radial-gradient(circle, #f36227 0%, #fd841d 50%, #fccd45 100%) !important;
			color: #fff !important;
		}
		&:focus {
			background: radial-gradient(circle, #f36227 0%, #fd841d 50%, #fccd45 100%) !important;
			color: #fff !important;
		}
		color: #202125;
	}
}
.app-menu__item.active {
	text-decoration: none;
	outline: none;
}

.app-menu__item {
    
        display: flex!important;
        align-items: center;
        span{
            display: block;
            margin-right: 5px;
            svg{
                font-size: 25px;
            }
        }
    
	position: relative;
	padding: 10px;
	font-size: 14px;
	border-left: 5px solid transparent;
	transition: border-left-color 0.3s ease, background-color 0.3s ease;
	margin: 0 10px 4px;
	border-radius: 4px;
	white-space: nowrap;
	word-break: keep-all;
	width: auto;
	display: block;
    text-decoration: none;
}


.top-header {
	transition: 0.3s;
    &::after{
        // position: absolute;
        // content: "";
        // height: 300px;
        // width: 100%;
        // left: 0;
        // top: 0;
        // background-color: radial-gradient(circle, #f36227 0%, #fd841d 50%, #fccd45 100%) !important;
        // z-index: -1;

    }
	background: linear-gradient(#ffb58d 10%, #feb477) !important;
	padding: 10px 15px;
	position: fixed;
	width: 100%;
	z-index: 99;
    width: calc(100% - 229px);
    right: 0;
    top: 0;
	@include mobilePotrait{
	padding: 10px 0px;

	}
	.top-search {
		width: 100%;
		transition: 0.5s;
		justify-content: flex-start;
        align-items: center;
		@include mobilePotrait{
		justify-content: space-between;
			
		}
		input {
			width: 100%;
			margin: 0 0 0 auto;
			height: 45px;
			border: none;
			border-radius: 2px;
			padding: 0 0 0 41px;
			background-color: #e2e5ed52;
			position: relative;
			
			&::placeholder {
				color: #9ea0a5;
				font-size: 14px;
				font-family: AirbnbCerealApp;
			}
			&::before {
				content: "\f002";
				font-family: FontAwesome;
			}
		}
	}
	.side-menu-icon {
		margin-top: 5px;
		background-color: #2aabfa;
		padding: 6px 5px 6px 8px;
		margin-right: 20px;
	}
	.user-top {
		justify-content: flex-end;
		margin-top: 0px;
		min-height: 100%;
		align-items: center;
		p{
			color: #fff;
			text-decoration: none;
			font-size: 14px;
			padding: 0px 12px 0 0px;
			font-family: "Poppins-SemiBold";
			margin-bottom: 0;
			cursor: pointer;
		}
	}
	.theme-btn{
		&.dropdown{
			.btn{
				min-width: auto;
				border-color: #9f2241;
                padding: 0;
                border: none;
                height: 40px;
                width: 40px;
                border-radius: 50%;
				background: radial-gradient(circle, #f36227 0%, #fd841d 50%, #fccd45 100%) !important;
				&:focus{
					box-shadow: 0 0 0 0.2rem #fd841d  ;
				}
			}
		}
	} 
	.dropdown {
		
		a {
			color: radial-gradient(circle, #f36227 0%, #fd841d 50%, #fccd45 100%) !important;
			text-decoration: none;
			font-size: 14px;
			padding: 7px 12px 7px 15px;
			font-family: "Poppins-SemiBold";
			margin-bottom: -7px;
			&:last-child{
				margin-bottom: 0;
			}
			
		}
		.fa {
			font-size: 12px;
			margin: 7px 0 0px 13px;
			color: #000000;
			cursor: pointer;
		}
	}
	.logo-image{
		.image-in{
			max-width: 167px;
    margin-top: 7px;
		}
		
	h2{
		font-size: 26px;
    color: #fff;
    margin-bottom: 0;
	}
	}
}


// toggle css start 

#toggle {
	cursor: pointer;
	width: 28px;
    margin: 8px 20px 0 0px;
	div {
		width: 100%;
		height: 5px;
		background: #fff;
		margin: 4px auto;
		transition: all 0.3s;
		backface-visibility: hidden;
	}
}
#toggle.on {
	.one {
		transform: rotate(45deg) translate(5px, 5px);
	}
	.two {
		opacity: 0;
	}
	.three {
		transform: rotate(-45deg) translate(7px, -8px);
	}
}

.app-menu {
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 2px grey;
		border-radius: 40px;
	}
	&::-webkit-scrollbar-thumb {
		background: red;
		border-radius: 40px;
		&:hover {
			background: #b30000;
		}
	}
}
