

@import '../css/mixins.scss';

.mb-40{
    margin: 0 0 40px 0;
}
.mb-20{
    margin: 0 0 20px 0;
}
 .main {

    background-color: #f5f5f5;
}

.flex-beetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.box{
    padding: 20px;
    background-color: #fff;
}

.slick-prev:before, .slick-next:before{
    font-size: 30px!important;
}
.inner-slide-nav{
    .slick-dots{
        bottom: 20px;
    }
    .slick-next,.slick-prev{
        width: 30px;
        height: 30px;
    }
    .slick-next {
        right: 15px;
        z-index: 99;
    }
    .slick-prev {
        left: 15px;
        z-index: 99;
    }
}

.btn{
    padding: 6px 16px;
    font-size: 0.75rem;
    min-width: 100px;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
}


.btn-group-right{
	display: flex;
	align-items: center;
	margin: 0 -7px;
	justify-content: flex-end;
	@include smallMobile{
		margin: 0;
		justify-content: space-between;
	}
	.btn{
		margin: 0 7px;
		@include smallMobile{
			min-width: 48%!important;
			width: 48%!important;
			margin: 0;
		}
	}
}
.height-auto{
    height: auto!important;
}

.form-text{
    color: #ff0000!important;
}