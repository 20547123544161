.box-graph {
    .box-con {
        h4 {
            color: #fff;
            padding-top: 0px;
            text-align: left;
            font-size: 20px;
        }
        p {
            color: #fff;
            text-align: left;
            padding: 0px;
            font-size: 20px;
        }
    }
    .box-icon {
        svg {
            font-size: 40px;
            color: #f1edede0;
        }
    }
}
.dashboard-section {
    .flex-beetween {
        align-items: flex-start;
    }
}

.couple-image {
    .item {
        .item-box {
            img {
                height: 330px;
                object-fit: cover;
                border-radius: 4px;
            }
        }
    }
}

.box {
    .graph {
        .box-con {
            p {
                text-align: center;
                font-weight: 700;
                margin: 1px 0 20px 0;
                font-size: 20px;
            }
        }
    }
}
.graph-manange {
    .box-graph {
        &.flex-beetween {
            align-items: flex-start;
        }
    }
}

.love-section {
    height: 100px;
    width: 100%;
    background-repeat: no-repeat;
}

.user-frofile-box {
    .box-image {
        height: 325px;
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.profile-detail {
    .profile-img {
        img {
            width: 120px;
            height: 140px;
            overflow: hidden;
            box-shadow: 0px 0px 19px #939393;
            border-radius: 17px;
            object-fit: cover;
        }
        width: 120px;
        height: 140px;
        margin: -40px 30px 0 0px;
    }
    .profile-name {
        h2 {
            color: #000;
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 0px;
        }
        p {
            font-size: 14px;
            margin-bottom: 15px;
            svg {
                font-size: 20px;
                color: #f97820;
            }
        }
    }
}

.user-profile {
    .user-frofile-box {
        background: #fff;
        padding-bottom: 20px;
        border-radius: 10px;
        overflow: hidden;
        .box-content {
            padding: 0 30px 0 40px;
        }
    }
}

.profile-tabs {
    .nav {
        display: flex !important;
    }
    .tabs-part{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 -59px 0;
        height: 82px;
      
    svg{
        font-size: 20px;
        color: #fc821d;
        cursor: pointer;
        position: relative;
    }
    }
}

.tabs-css {
    .slick-slider {
        overflow: hidden;
    }
    .tab-content{
        .nav-tabs{
            margin: 0px!important;
            padding: 15px 0;
            background-color: #fff;
        }
    }
    .nav-tabs {
        border-bottom: transparent;
        display: flex!important;
        white-space: nowrap;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        scroll-behavior: smooth;
        margin-bottom: 25px!important;
      
        
        &::-webkit-scrollbar {
            width: 10px;
            display: none;
          }
       
    }
    .nav-item {
        .nav-link {
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            min-width: 173px;
            background: transparent;
            &:hover{
                border-color: transparent;
            }
            &.active {
                color: #f97521;
                background-color: transparent;
                border-color: transparent;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    bottom: 0;
                    background-color:  #f97521;
                    height: 3px;
                    width: 80%;
                    left: 10%;
                }
            }
            &:focus,
            &:focus-visible {
                outline: none;
            }
        }
    }
}

// .slick-slide{
//     .tab-content{
//         display: none;
//     }
// }

// .slick-slide{
//     .tab-content{
//         display: block;
//     }
// }

.info-box {
    .info-header {
        border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
        padding: 16px;
        h2 {
            margin-bottom: 0;
            font-size: 16px;
        }
    }
    .info-content {
        .info-content {
            height: auto;
        }
        padding: 16px;
        background-color: #fff;
        overflow-x: hidden;
        height: 300px;
        overflow-y: overlay;
        &::-webkit-scrollbar {
            width: 7px;
            height: 12px;
          }
          
          /* Track */
          &::-webkit-scrollbar-track {
            background: #fff; 
          }
           
          /* Handle */
          &::-webkit-scrollbar-thumb {
          background: #d9d0d0;
          border-radius: 40px;

          }
          
        .content-box {
            .form-group{
                margin: 10px 0!important;
            }
            .content-box{
                border-bottom: none;
                padding: 0;
                margin-bottom: 7px;
                
            }
            display: flex;
            border-bottom: 1px solid #eaeaea;
            padding: 10px 0 10px 0;
            flex-wrap: wrap;
            .block-100{
                display: block;
                width: 100%;
            }
            &:last-child {
                border-bottom: 0;
            }
            h3 {
                margin: 0 10px 0 0;
                font-size: 16px;
                display: flex;
                white-space: nowrap;
                font-weight: bold;
            }
            p {
                margin-bottom: 0;
                font-size: 15px;
                word-break: break-all;
            }
        }
    }
}

.info-line.new-circles {
    &.badges {
        .title {
            text-align: center;
            font-size: 14px;
            font-weight: bold;
        }
        .round-box {
            overflow: hidden;
            img {
                height: 100%;
                width: 100%;
            }
        }
    }
}

.info-line.new-circles .round-box {
    margin: 10px auto 40px auto;
}

.round-box {
    background: #fff;
    height: 40px;
    width: 40px;
    line-height: 30px;
    border: 2px solid transparent;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    border-radius: 50%;
    box-shadow: -1px 2px 5px #8b8b8b;
}


.images-box-wine {
    position: relative;
    min-height: 391px;
    &.decrease {
        min-height: 150px;
    }
    .loader-wrapper {
        position: absolute;
        z-index: 0;
    }
    .images-box {
        &:hover {
            .icons-action {
                display: block;
            }
        }
        margin: 7px 0;

        position: relative;
        .icons-action {
            position: absolute;
            transition: 0.3s;
            right: 10px;
            top: 10px;
            width: 30px;
            line-height: 28px;
            height: 30px;
            background-color: #9f2241;
            text-align: center;
            border-radius: 50%;
            cursor: pointer;
            display: none;
            svg {
                fill: #fff;
            }
        }
        img {
            height: 190px;
            width: 100%;
            object-fit: cover;
        }
    }
}

.detail-profile{
    display: flex;
    border: 1px solid #ffb083;
    .content-box{
        
        padding: 0!important;
        a{
            font-size: 14px;
            text-decoration: none;
        }
    }
    .image-box{
        margin-right: 20px;
        img{
            height: 96px;
            width: 96px;
            object-fit: cover;
        }
    }
    .box-user-con{
        .content-box{
            display: flex;
            border-bottom: 0;
            margin: 0 0 10px 0;
        }
    }
}

/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
  }
  
  /* Style the buttons that are used to open the tab content */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
  }
  
  /* Change background color of buttons on hover */
  .tab button:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current tablink class */
  .tab button.active {
    background-color: #ccc;
  }
  
  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }


  
// loader css start

.loader-wrapper {
    &.absolute-sec {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
    }
    background: rgba(255, 255, 255, 0.84);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 9999;
    top: 0;
    left: 0;
}

.loader {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
}

.loader:before {
    content: "";
    width: 100px;
    height: 100px;
    border: 5px solid rgba(0, 0, 0, 0.05);
    border-top-color: #fc821d;
    border-radius: 50%;
    animation: spin 1.5s infinite linear;
    display: block;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader svg {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 52px;
    color: #fc821d;
}
.loader-sec {
    min-height: 300px;
    position: relative;
}
.model-form{
    padding: 20px 15px;
}

.action-top{
    span{
        cursor: pointer;
    }
    display: flex;
    flex-wrap: nowrap;
    margin: 0 -5px;
    .icons-action{
        margin: 0 5px;
    }
}

// loader css end

.form-check{
    &.with-input{
        label{
            width: 100%;
        }
        .form-check-input{
            margin-top: 10px;
        }
    }
} 
.guide-modal{
    .modal-dialog{
        max-width: 600px;
        .jodit-container{
            margin-bottom: 25px;
        }
    }
}

// sidebar mini css
.app-sidebar, .app-menu__item span, .logo-name h1,.top-header{
    transition: 0.3s;
}
.main,  .top-header{
    transition: 0.5s!important;
}
.sidebar-mini{
    .app-sidebar{
        width: 80px;
    }
    .app-menu__item span {
        display: block;
        margin-right: 30px;
    }
    .logo-name h1 {
        font-size: 20px;
    }
     .main {
        margin-left: 80px!important;
    }
    .top-header{
        width: calc(100% - 80px);

    }
}

.tab-table{
    padding: 0!important;
    .table-order{
        border-radius: 0;
    }
}

.chartjs-render-monitor {
    width: 100%!important;
}